<template>
  <div>
    <el-select
      :value="selected"
      @input="dispatch"
      :placeholder="placeholder || $t('message.managerTypes')"
      filterable
      clearable
      :size="size"
      class="w-100"
    >
      <el-option
        v-for="(managerType, index) in managerTypes"
        :key="'managerTypes' + index"
        :label="managerType.name"
        :value="managerType.id"
      ></el-option>
    </el-select>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  props: {
    size: {
      default: "small",
    },
    placeholder: {
      default: null,
    },
    id: {
      default: null,
    },
  },
  watch: {
    id: {
      handler: function () {
        this.selected = this.id;
      },
      immediate: true,
    },
  },
  data() {
    return {
      selected: null,
    };
  },
  mounted() {
   if (this.managerTypes && this.managerTypes.length === 0) this.updateInventory();
  },
  computed: {
    ...mapGetters({
      managerTypes: "managerTypes/inventory",
    }),
  },
  methods: {
    ...mapActions({
      updateInventory: "managerTypes/inventory",
    }),
    dispatch(e) {
      this.$emit("input", e);
      this.selected = e;
    },
  },
};
</script>
