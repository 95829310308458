<template>
    <div>
      <el-select :value="selected" @input="dispatch" :placeholder="placeholder || $t('message.telegramBotSetting')" filterable clearable
        :size="size" :multiple="multiple" class="w-100">
        <el-option v-for="(botSetting,index) in botSettings" :key="'botSettings-' + index" :label="botSetting.name" :value="botSetting.id">
            <span class="mm_select_additional_container">
                {{ botSetting.name }} <span class="mm_select_additional_info">{{ botSetting.filial ? botSetting.filial.name : '' }}</span>
            </span>
        </el-option>
      </el-select>
    </div>
</template>
<script>
import {mapGetters, mapActions} from "vuex";
export default {
    props: {
        size: {
            default: 'small'
        },
        placeholder: {
            default: null,
        },
        id:{
            default: null
        },
        select_first: {
            type: Boolean,
            default: true
        },
        multiple: {
            type: Boolean,
            default: false
        },
        filial_ids: {
            default: null,
        },
        user_id: {
            default: null,
        }
    },
    watch:{
        id:{
            handler: function() {
                this.selected = this.id
            },
            immediate: true
        },
        filial_ids:{
            handler: function(newVal) {
                if(newVal){
                    this.debouncedUpdateInventory(this);
                }
            },
            immediate: true
        },
        user_id:{
            handler: function(newVal) {
                if(newVal){
                    this.debouncedUpdateInventory(this);
                }
            },
            immediate: true
        }
    },
    data() {
        return {
            selected: null,
        }
    },
    mounted() {
        if (this.botSettings && this.botSettings.length === 0) 
        this.debouncedUpdateInventory(this);
    },
    computed: {
        ...mapGetters({
            botSettings: 'telegramBotSetting/inventory'
        })
    },
    methods: {
        ...mapActions({
            updateInventory: 'telegramBotSetting/inventory'
        }),
        dispatch(e) {
            this.$emit('input', e);
            this.selected = e
        },
        debouncedUpdateInventory: _.debounce((self) => {
            if (_.isFunction(self.updateInventory)) {
                self.updateInventory({filial_ids: self.filial_ids, user_id: self.user_id})
                    .then((res) => {
                        self.loading = false;
                        if(!self.selected && self.botSettings.length > 0 && self.select_first){
                            self.selected = self.botSettings[0].id;
                            self.$emit('input', self.selected);
                        }
                    })
                    .catch((err) => { 
                        self.loading = false; 
                    });
            }
        }, 300),
    },
}

</script>
  