<template>
  <div>
    <el-select
		:value="selected"
		@input="dispatch"
		:placeholder="placeholder || $t('message.department')"
		filterable
		clearable
		:size="size"
		class="w-100"
    :multiple="multiple"
		@change="Cchange"
    >
      <el-option
        v-for="(department, index) in departments"
        :key="'department-' + index"
        :label="department.name"
        :value="department.id"
      ></el-option>
    </el-select>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  props: {
    size: {
      default: "small",
    },
    placeholder: {
      default: null,
    },
    id: {
      default: null,
    },
    multiple: {
      default: false,
    },
    filial_ids: {
      default: null,
    },
  },
  watch: {
    id: {
      handler: function () {
		this.selected = this.id;
      },
      immediate: true,
    },
  },
  data() {
    return {
      selected: null,
    };
  },
  	mounted() {
   		if (this.departments && this.departments.length === 0) this.updateInventory();
  	},
  computed: {
    ...mapGetters({
      departments: "departments/inventory",
    }),
  },
  methods: {
    ...mapActions({
      updateInventory: "departments/inventory",
    }),
    dispatch(e) {
      this.$emit("input", e);
      this.selected = e;
    },
	 Cchange(e){
		this.$emit('c-change', e);
		this.selected = e;
	 }
  },
};
</script>
